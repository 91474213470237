<template>
  <div>
    <!-- <Preloader />
    <Header /> -->
    <ContactUs />
    <!-- <Footer /> -->
  </div>
</template>

<script>
// import Preloader from "@/components/Preloader.vue";
// import Header from "@/components/TheHeader.vue";
import ContactUs from "@/components/ContactUs.vue";
// import Footer from "@/components/TheFooter.vue";
export default {
  components: {
    // Preloader,
    // Header,
    ContactUs,
    // Footer
  }
};
</script>
